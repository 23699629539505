import * as React from "react"
import { useEffect, useState } from "react"
import { graphql } from "gatsby"

import Layout from "../../../../../components/Layout/layout"
import Seo from "../../../../../components/seo"
import Search from "../../../../../components/Category/search"
import Listing from "../../../../../components/Category/listing"
import PopularServices from "../../../../../components/Category/popularServices"
import Faq from "../../../../../components/Index/faq"
import HowItWorks from "../../../../../components/Index/howItWorks"
import { setCityImage } from "../../../../../services/imageHelper"

import "../../../../../css/local_list.css"

const { slugify, unSlugify } = require("../../../../../services/slugHelper")

const IndexPage = props => {
  const [businesses, setBusinesses] = useState([])
  const [basePath, setBasePath] = useState("")

  const category = typeof props.category !== "undefined" ? unSlugify(props.category) : ""
  const cityName = typeof props.city !== "undefined" ? unSlugify(props.city) : ""

  let cityData = props.data.allCity.nodes.filter(city => cityName === city.name)

  cityData = cityData.length > 0 ? cityData[0] : ""

  useEffect(() => {
    fetch(`${process.env.GATSBY_API_URL}/city-industry/list-business/${cityName}/${category}`)
      .then(response => response.json())
      .then(result => {
        setBusinesses(result.data)
      })

    document.body.classList.add("ClientToVendor")

    const countryCode = props.country.toUpperCase()
    const regionCode = props.region.toUpperCase()

    let location = {}
    location.countryCode = countryCode
    location.regionCode = regionCode

    setBasePath(("/" + countryCode + "/" + regionCode + "/" + slugify(cityName)).toLowerCase())

    setCityImage(
      location,
      `${process.env.GATSBY_WRANGLER_URL}/${countryCode}/${regionCode}/${props.city}.jpg`
    )
  }, [props, cityName, category])

  return (
    <Layout basePath={basePath}>
      <Seo title="Click to Vendor" />
      <Search category={category} city={cityName} cityData={cityData} />
      <Listing category={category} city={cityName} businesses={businesses} />
      <PopularServices locationName={cityName} basePath={basePath} />
      <Faq />
      <HowItWorks />
    </Layout>
  )
}

export default IndexPage

export const cityQuery = graphql`
  query {
    allCity {
      nodes {
        id
        name
        latitude
        longitude
      }
    }
  }
`
