import * as React from "react"
import { Link } from "gatsby"

const Listing = ({ category, city, businesses }) => {
  return (
    <section className="listing_section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="listing_headline text-center">
              Best {category} in {city}
            </h2>
          </div>
          <div className="col-lg-12 listing_container">
            <div className="row">
              <div className="col-lg-8">
                {businesses.map((business, index) => {
                  return (
                    <div className="pro-box" key={index}>
                      <div className="icon-holder get-started">
                        <img
                          className=""
                          src={process.env.GATSBY_STATIC_IMAGE_URL + "/local/am1.jpg"}
                          alt=""
                          title=""
                        />
                      </div>
                      <div className="pro-content">
                        <div className="content-title">
                          <h3>
                            <Link to={business.slug}>{business.name}</Link>
                          </h3>
                          <div className="showCheckBox">
                            <input
                              type="checkbox"
                              name="List"
                              id={"ListCheckbox" + index}
                              className="css-checkbox ml-sm-2"
                              value="move"
                            />
                            <label
                              htmlFor={"ListCheckbox" + index}
                              className="css-checkbox-label radGroup2 ml-sm-2 mb-0"
                            />
                          </div>
                        </div>
                        <div className="content-stars">
                          <span className="pro-rating">5.0</span>
                          <i className="bi bi-star-fill stars active" />
                          <i className="bi bi-star-fill stars active" />
                          <i className="bi bi-star-fill stars active" />
                          <i className="bi bi-star-fill stars active" />
                          <i className="bi bi-star-fill stars active" />
                          <span className="total_review">(1)</span>
                        </div>
                        <div className="content-subtitle">
                          <span className="about-txt">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                            Ipsum has been the industry's standard dummy text ever since the 1500s
                          </span>
                        </div>
                        <div className="content-facts d-none d-sm-block">
                          <span>
                            <i className="bi bi-geo-alt-fill" /> {business.address}
                          </span>
                          <span>
                            <i className="bi bi-envelope" /> {business.email}
                          </span>
                          <span>
                            <i className="bi bi-phone" /> {business.phone}
                          </span>
                        </div>
                        <div className="content-nav d-block d-sm-none">
                          <button className="btn btn-secondary btn-sm btnRequestQuote">
                            Request a Quote
                          </button>
                          <Link to="#" className="btn btn-backlead btn-sm">
                            View Profile
                          </Link>
                          <span className="distanceLabel">
                            <i className="bi bi-signpost-2-fill" /> 10 miles
                          </span>
                        </div>
                      </div>
                      <div className="content-button d-none d-sm-block">
                        <button className="btn btn-secondary btn-lg btnRequestQuote w-100">
                          Request a Quote
                        </button>
                        <Link to={business.slug} className="btn btn-backlead btn-lg d-none d-sm-block">
                          View Profile
                        </Link>
                        <span className="distanceLabel">
                          <i className="bi bi-signpost-2-fill"></i> 10 miles
                        </span>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="col-lg-4" id="sticky_sidebar">
                <div className="profile-contactbox">
                  <h3 className="contactBoxHeading">About My Vehicle</h3>
                  <div className="contactBoxInner">
                    <div className="weight-300 mb-3">I'm looking to get this done:</div>
                    <form id="FrmQuote" method="post">
                      <input type="hidden" name="formid" value="1959F87" />
                      <input type="hidden" name="ip_address" id="ip_address" value="" />
                      <input type="hidden" name="browser" id="SC_browser" value="" />
                      <input type="hidden" name="trackingvars" className="trackingvars" id="trackingvars" />
                      <input
                        type="hidden"
                        name="timezone"
                        value=""
                        id="SC_fh_timezone"
                        className="SC_fh_timezone"
                      />
                      <input
                        type="hidden"
                        name="language"
                        id="SC_fh_language"
                        className="SC_fh_language"
                        value=""
                      />
                      <input
                        type="hidden"
                        name="capturepage"
                        id="SC_fh_capturepage"
                        className="SC_fh_capturepage"
                        value=""
                      />
                      <input
                        type="hidden"
                        name="vTags"
                        id="vTags"
                        value="#support #helpdesk #website_support"
                      />
                      <input type="hidden" name="sc_lead_referer" id="sc_lead_referer" value="" />
                      <input type="hidden" name="iSubscriber" value="817" />
                      <input type="hidden" name="sc_referer_qstring" value="" id="sc_referer_qstring" />

                      <div className="mb-3">
                        <textarea
                          className="form-control reqField"
                          required
                          name="reqMessage"
                          id="reqMessage"
                          rows="5"
                          placeholder="What do you need? How can we help?"
                        />
                      </div>
                      <div className="mb-3 uploadBox">
                        <label htmlFor="formFile" className="form-label">
                          Optional: Upload Video
                        </label>
                        <input id="file-demo" type="file" className="form-control swift-gallery" required />
                        <small className="tipPart">
                          TIP: A short selfie-video with your phone can save you time & money!
                        </small>
                      </div>

                      <div className="signup-or-separator">
                        <span className="signup-or-separator--text">About You</span>
                        <hr />
                      </div>

                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control reqField"
                          name="reqName"
                          id="reqName"
                          placeholder="Name"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <input
                          type="email"
                          className="form-control reqField"
                          name="reqEmailOffDomain"
                          id="reqEmailOffDomain"
                          placeholder="Email"
                          required
                        />
                        <input name="reqEmail" id="reqEmail" type="email" style={{ display: "none" }} />
                      </div>
                      <div className="mb-3">
                        <div className="row">
                          <div className="col-lg-6 col-md-9">
                            <input
                              type="text"
                              className="form-control swift_phone_field_us reqField"
                              name="reqPhone"
                              id="reqPhone"
                              placeholder="Phone"
                              required
                            />
                          </div>
                          <div className="col-lg-6 col-md-3">
                            <input
                              type="checkbox"
                              name="List"
                              id="ListCheckbox"
                              className="css-checkbox"
                              value="move"
                              defaultChecked
                            />
                            <label htmlFor="ListCheckbox" className="css-checkbox-label radGroup2 mb-0">
                              OK to SMS{" "}
                              <span
                                data-toggle="tooltip"
                                data-placement="top"
                                title="No spam, never sold, about your house or this property only."
                              >
                                <i className="bi bi-question-circle-fill" />
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control reqField"
                          name="reqAddress"
                          id="reqAddress"
                          placeholder="Address"
                        />
                      </div>

                      <div className="mb-2">
                        <button type="submit" className="btn btn-local btn-lg w-100 btnSubmit">
                          Request My Quote(s)
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Listing
