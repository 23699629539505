import * as React from "react"
import GoogleMapReact from "google-map-react"

const Map = ({ cityData }) => {
  const defaultProps = {
    center: {
      lat: parseFloat(cityData.latitude),
      lng: parseFloat(cityData.longitude),
    },
    zoom: 10,
  }

  return (
    <div className="col-lg-5 col-md-5">
      <div className="map_container">
        <div id="local_map" style={{ width: "100%", height: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyBvcAXg1ocsFA4q0Vj72erVYINdsi7vHDc" }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            mapTypeId="moon"
          ></GoogleMapReact>
        </div>
      </div>
    </div>
  )
}

export default Map
