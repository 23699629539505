import * as React from "react"

import Map from "./map"

const Search = ({ category, city, cityData }) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 p-0">
          <div className="jumbotron hero_section text-center mb-0 d-flex flex-column justify-content-center position-relative">
            <img
              src={process.env.GATSBY_STATIC_IMAGE_URL + "/charleston-handyman.png"}
              alt=""
              className="category_avatar d-none d-md-block"
            />
            <div className="row g-0 align-items-center">
              <div className="col-lg-7 col-md-7 px-0 px-md-3">
                <div className="row">
                  <div className="col-md-8 cat-box-info">
                    <img
                      src={process.env.GATSBY_STATIC_IMAGE_URL + "/charleston-handyman.png"}
                      alt=""
                      className="category_avatar_mobile"
                    />
                    <h1 className="display-4">
                      Best {category} in {city}
                    </h1>
                    <div className="pt-md-5 pt-3">
                      <form>
                        <div className="row">
                          <div className="col-xl-9 col-lg-9 mx-auto">
                            <h3 className="mb-3 VendorToClientLabel">
                              Where do you need your {category} service?
                            </h3>
                            <h3 className="mb-3 ClientToVendorLabel">Search Nearby {category}</h3>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-9 col-lg-10 mx-auto">
                            <div className="input-group">
                              <select className="form-select location-type">
                                <option value="Home">Home</option>
                                <option value="Office">Office</option>
                                <option value="Other">Other</option>
                              </select>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Update Address & Search Nearby"
                              />
                              <button className="btn btn-lg btnSearch" type="button">
                                GO <i className="bi bi-arrow-right-circle" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <img
                      src={process.env.GATSBY_STATIC_IMAGE_URL + "/charleston-handyman.png"}
                      alt=""
                      className="category_avatar_tablet"
                    />
                  </div>
                  <div className="col-md-4"></div>
                </div>
              </div>
              {cityData && <Map cityData={cityData} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Search
