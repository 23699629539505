import * as React from "react"
import { Link } from "gatsby"
import { useContext } from "react"
import LocationContext from "../Location/location"

const PopularServices = ({ locationName, basePath }) => {
  const location = useContext(LocationContext)
  locationName = typeof locationName === "undefined" ? location.city : locationName

  return (
    <section className="popularServices">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center mb-md-5 mb-3">
            <h2>Related Services in {locationName}</h2>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 mb-4 mb-md-0">
            <div className="card">
              <Link to={basePath + "/auto-body"}>
                <img
                  src={process.env.GATSBY_STATIC_IMAGE_URL + "/Auto-Body.jpg"}
                  className="img-fluid"
                  alt="Auto Body"
                />
              </Link>
              <div className="card-body p-0">
                <h4 className="card-title mb-0 text-center">
                  <Link to={basePath + "/auto-body"} className="d-block py-4">
                    <strong>{locationName} Auto Body</strong>
                  </Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 mb-4 mb-md-0">
            <div className="card">
              <Link to={basePath + "/oil-change"}>
                <img
                  src={process.env.GATSBY_STATIC_IMAGE_URL + "/Oil-Change.jpg"}
                  className="img-fluid"
                  alt="Oil Change"
                />
              </Link>
              <div className="card-body p-0">
                <h4 className="card-title mb-0 text-center">
                  <Link to={basePath + "/oil-change"} className="d-block py-4">
                    <strong>{locationName} Oil Change</strong>
                  </Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card">
              <Link to={basePath + "/tire-shops"}>
                <img
                  src={process.env.GATSBY_STATIC_IMAGE_URL + "/Tire-Shops.jpg"}
                  className="img-fluid"
                  alt="Tire Shops"
                />
              </Link>
              <div className="card-body p-0">
                <h4 className="card-title mb-0 text-center">
                  <Link to={basePath + "/tire-shops"} className="d-block py-4">
                    <strong>{locationName} Tire Shops</strong>
                  </Link>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PopularServices
