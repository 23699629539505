export const load = src => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener("load", resolve)
    image.addEventListener("error", reject)
    image.src = src
  })
}

export const setCityImage = (city, backgroundImage) => {
  load(backgroundImage)
    .then(() => {
      document.getElementsByClassName("hero_section")[0].style.backgroundImage = `url(${backgroundImage})`
    })
    .catch(() => {
      setRegionImage(city, `${process.env.GATSBY_WRANGLER_URL}/${city.countryCode}/${city.regionCode}.jpg`)
    })
}

export const setRegionImage = (region, backgroundImage) => {
  load(backgroundImage)
    .then(() => {
      document.getElementsByClassName("hero_section")[0].style.backgroundImage = `url(${backgroundImage})`
    })
    .catch(() => {
      setCountryImage(`${process.env.GATSBY_WRANGLER_URL}/${region.countryCode}/_${region.countryCode}.jpg`)
    })
}

export const setCountryImage = backgroundImage => {
  load(backgroundImage)
    .then(() => {
      document.getElementsByClassName("hero_section")[0].style.backgroundImage = `url(${backgroundImage})`
    })
    .catch(() => {
      document.getElementsByClassName(
        "hero_section"
      )[0].style.backgroundImage = `url(${process.env.GATSBY_WRANGLER_URL}/default.jpg)`
    })
}
